import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

export default function RecentList() {
  return (
    <Container className="list__recent">
      {/* <Row>
        <h2 className="heading--primary wow animate__animated animate__fadeInUp">
          Incubatees Expo-2024
        </h2>
      </Row>
      <Row>
        <p
          style={{ textAlign: "justify", fontSize: "1.2em", fontWeight: 500 }}
          className="wow animate__animated animate__fadeInUp"
        >
          <img
            alt="banner"
            src="/assets/images/Incubatee_Expo_24.png"
            className="wow animate__animated animate__fadeInUp list__recent--image"
          />
          The event aims to provide a platform for ABIF registered incubatees to
          exhibit their products, prototypes, and services. Also, this event
          will provide attendees with hands-on experiences and ample networking
          time; thus, allowing participants to connect with potential
          co-founders, FPOs, mentors, collaborators and sell products to campus
          community.
          <br />
          <a href="/program/incubatees-expo-2024">
            <Button className="mt-3 button--primary">View More</Button>
          </a>
        </p>
      </Row> */}
      <Row>
        <h2 className="heading--primary wow animate__animated animate__fadeInUp">
          Nurturing Agri Business and Rural Development Ecosystem
        </h2>
      </Row>
      <Row>
        <p
          style={{ textAlign: "justify", fontSize: "1.2em", fontWeight: 500 }}
          className="wow animate__animated animate__fadeInUp"
        >
          <img
            alt="banner"
            src="/assets/images/banner1.png"
            className="wow animate__animated animate__fadeInUp list__recent--image"
          />
          ABIF IIT Kharagpur's incubation program is a transformative platform
          tailored for startups in agriculture, rural development and allied
          domains. With expert mentorship and state-of-the-art facilities, it
          empowers entrepreneurs to bring innovative solutions to the
          agriculture and rural development sector. Through a rigorous and
          supportive ecosystem, ABIF IIT Kharagpur nurtures promising ventures,
          fostering their growth and impact in the ever-evolving agricultural
          landscape. Join us to cultivate your ideas and the future of
          sustainable agriculture!
          <br />
          <a href="/apply/incubation">
            <Button className="mt-3 button--primary">
              Apply for Incubation
            </Button>
          </a>
        </p>
      </Row>
    </Container>
  );
}
