import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Subscribe from "./Home/Subscribe";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaLinkedin,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer--div">
        <Subscribe />
        <Container className="footer--container">
          <Row>
            <Col sm={12} md={5}>
              <img
                src="/assets/images/logo.png"
                alt="Logo"
                className="footer--logo wow animate__animated animate__fadeInUp"
              />
              <p className="footer--brand wow animate__animated animate__fadeInUp">
                कृषि व्यवसाय उद्भवन प्रतिष्ठान <br />
                भारतीय प्रौद्योगिकी संस्थान खड़गपुर <br />
                AGRI BUSINESS INCUBATION FOUNDATION <br />
                INDIAN INSTITUTE OF TECHNOLOGY KHARAGPUR
              </p>
              <div className="footer--social wow animate__animated animate__fadeInUp">
                <a
                  href="https://www.facebook.com/ABIF.IIT.KGP"
                  className="footer--social--icon wow animate__animated animate__fadeInUp"
                  target="_blank"
                >
                  <FaFacebookSquare size={32} />
                </a>
                <a
                  href="https://twitter.com/abifiitkgp"
                  className="footer--social--icon wow animate__animated animate__fadeInUp"
                  target="_blank"
                >
                  <FaTwitterSquare size={32} />
                </a>
                <a
                  href="https://www.linkedin.com/company/agri-business-incubation-foundation-iit-kharagpur-abif/"
                  className="footer--social--icon wow animate__animated animate__fadeInUp"
                  target="_blank"
                >
                  <FaLinkedin size={32} />
                </a>
                <a
                  href="https://www.instagram.com/abif_iitkgp/"
                  className="footer--social--icon wow animate__animated animate__fadeInUp"
                  target="_blank"
                >
                  <FaInstagram size={32} />
                </a>
                <a
                  href="https://www.youtube.com/@tbimanagerabiciitkgp6115"
                  className="footer--social--icon wow animate__animated animate__fadeInUp"
                  target="_blank"
                >
                  <FaYoutube size={32} />
                </a>
              </div>
            </Col>
            <Col sm={12} md={3}>
              <strong className="footer--link--title wow animate__animated animate__fadeInUp">
                Quick Links
              </strong>
              <a
                className="footer--link wow animate__animated animate__fadeInUp"
                href="/program"
              >
                Programs
              </a>
              <a
                className="footer--link wow animate__animated animate__fadeInUp"
                href="/startups"
              >
                Startups
              </a>
              <a
                className="footer--link wow animate__animated animate__fadeInUp"
                href="/mentors"
              >
                Mentors
              </a>
              <a
                className="footer--link wow animate__animated animate__fadeInUp"
                href="/fpo"
              >
                FPOs
              </a>
              <a
                className="footer--link wow animate__animated animate__fadeInUp"
                href="/events"
              >
                Events
              </a>
              <a
                className="footer--link wow animate__animated animate__fadeInUp"
                href="/tenders"
              >
                Notices
              </a>
            </Col>
            <Col sm={12} md={4}>
              <strong className="footer--info wow animate__animated animate__fadeInUp">
                Contact Us:
              </strong>
              <span className="footer--info wow animate__animated animate__fadeInUp">
                Address: IIT Kharagpur Campus, Paschim Medinipur, Kharagpur,
                West Bengal-721302
              </span>
              <span className="footer--info wow animate__animated animate__fadeInUp">
                Phone: 03222-214633
              </span>
              <span className="footer--info wow animate__animated animate__fadeInUp">
                Email: info@abifiitkgp.com
              </span>
              <span className="footer--info wow animate__animated animate__fadeInUp">
                CIN: U85300WB2021INPL243292
              </span>
            </Col>
          </Row>
        </Container>
        <p className="footer--copyrights wow animate__animated animate__fadeInUp">
          All Rights Reserved &copy; 2023 ABIF IIT Kharagpur
        </p>
      </div>
    </div>
  );
}
