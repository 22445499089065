import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useEffect, useState } from "react";
import { getContent, getStatsList } from "../../api/api";

export default function OurStory() {
  // const [list, setList] = useState([]);
  const [aboutContent, setAboutContent] = useState([]);
  useEffect(() => {
    // getStatsList().then((result) => {
    //   setList(result);
    // });
    getContent("about").then((result) => {
      setAboutContent(result);
    });
  }, []);
  return (
    <>
      <div className="home__story">
        <Container>
          {aboutContent.length > 0 && (
            <>
              <Row>
                <h2
                  className="heading--primary wow animate__animated animate__fadeInUp"
                  style={{ paddingBottom: "0.4em" }}
                >
                  Our Story
                </h2>
              </Row>
              <Row>
                <p className="home__story--content wow animate__animated animate__fadeInUp">
                  {aboutContent[0]?.content}
                  <br />
                  {aboutContent[1]?.content}
                </p>
              </Row>
            </>
          )}
        </Container>
      </div>
    </>
  );
}
