import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import { getUpdatesList } from "../../api/api";
import { MdOpenInNew } from "react-icons/md";

export default function SocialMediaPosts() {
  const [list, setList] = useState([]);
  useEffect(() => {
    getUpdatesList().then((result) => {
      setList(result);
    });
  }, []);
  return (
    <>
      <Container className="home__social-media">
        <Row>
          <Col>
            <Row>
              <h2 className="heading--primary wow animate__animated animate__fadeInUp">
                Recent Updates
              </h2>
            </Row>
            <Row>
              <div className="home__social-media--scroll wow animate__animated animate__fadeInUp">
                {list
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  ?.map((item) => (
                    <div className="home__social-media--scroll--card wow animate__animated animate__fadeInUp">
                      <p className="home__social-media--scroll--date">
                        {new Date(item.date).toLocaleDateString()}
                      </p>
                      <p className="home__social-media--scroll--content">
                        {item.title}{" "}
                        {item.link &&
                          item.link !== "#" &&
                          item.link !== "/" && (
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <MdOpenInNew />
                            </a>
                          )}
                      </p>
                    </div>
                  ))}
              </div>
            </Row>
          </Col>
          <Col md="auto">
            <Row>
              <h2 className="heading--primary">Social Media Posts</h2>
            </Row>
            <Row>
              <div className="home__social-media--main">
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FABIF.IIT.KGP&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=false&hide_cover=false&show_facepile=true&appId"
                  // width="500"
                  height="500"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  title="facebook"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
                {/* <FacebookPage />
            <div id="twitter-timeline"></div>
            <LinkedInCompanyPage/> */}
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
