import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { useNavigate, useParams } from "react-router-dom";
import { getMentor, submitAppointment } from "../api/api";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

const initialValues = {
  mentor: "",
  name: "",
  email: "",
  phone: "",
  message: "",
};

export default function MentorInfo() {
  const [mentor, setMentor] = useState({});
  const { mentorId } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [data, setData] = useState(initialValues);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleClose = () => {
    setData(initialValues);
    setFormSubmitted(false);
    setShow(false);
  };
  const handleShow = () => {
    setData(initialValues);
    setFormSubmitted(false);
    setShow(true);
  };

  const handleSubmit = () => {
    if (!data.mentor) {
      alert("Unknown Error!");
    } else if (!data.name) {
      alert("Name is required");
    } else if (
      !data.email ||
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(data.email)
    ) {
      alert("Enter a valid Email");
    } else if (!data.phone || data.phone.length !== 10) {
      alert("Enter a valid phone number");
    } else if (!data.message) {
      alert("Query field cannot be empty");
    } else if (
      data.message
        .trim()
        .split(/\s+/)
        .filter((word) => word !== "").length > 100
    ) {
      alert("Query can't me more than 100 words");
    } else {
      submitAppointment(data)
        .then((result) => {
          setData(initialValues);
          setFormSubmitted(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (mentorId) {
      getMentor(mentorId).then((result) => {
        if (result.length === 0) {
          navigate("/mentors");
        } else {
          setMentor(result[0]);
        }
      });
    }
  }, [mentorId]);
  return (
    <>
      <Container style={{ paddingTop: "4%", paddingBottom: "4%" }}>
        <p style={{ fontSize: "2em", marginBottom: 0 }}>{mentor.name}</p>
        <p>{mentor.position}</p>
        <Row style={{ textAlign: "left" }}>
          <Col sm={12} md={9}>
            <p>{mentor.description}</p>
            {/* <p style={{ fontSize: "1.6em", marginBottom: 0 }}>Experience:</p>
          <ul>
            {mentor.experience &&
              JSON.parse(mentor.experience)?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
          </ul> */}
            <p style={{ fontSize: "1.6em", marginBottom: 0 }}>
              Domain Expertise:
            </p>
            <ul>
              {mentor.domain_expertise &&
                JSON.parse(mentor.domain_expertise)?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
            </ul>
            {/* <p style={{ fontSize: "1.6em", marginBottom: 0 }}>
            Support Provided:
          </p>
          <ul>
            {mentor.support_provided &&
              JSON.parse(mentor.support_provided)?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
          </ul>
          <p style={{ fontSize: "1.6em", marginBottom: 0 }}>FPOs Mentored:</p>
          <ul>
            <li>FPO Name </li>
            <li>FPO Name </li>
            <li>FPO Name </li>
          </ul> */}
          </Col>
          <Col sm={12} md={3}>
            <img
              src={mentor.image}
              style={{
                width: "100%",
                borderRadius: "2em",
                border: "1px solid black",
                marginBottom: "1em",
              }}
              alt="Mentor"
            />
            <Button
              className="w-100 button--primary"
              onClick={() => {
                handleShow();
                setData({ ...data, mentor: mentor.name });
              }}
            >
              Book Appointment
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} style={{ textAlign: "left" }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {formSubmitted ? (
              <>We have received your appointment</>
            ) : (
              <>Book an Appointment with {data.mentor}</>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSubmitted ? (
            <p className="" style={{ textAlign: "center" }}>
              Thank you for submitting yur query for appointment.
              <br />
              We will get back to you shortly.
            </p>
          ) : (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="full name"
                  value={data.name}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number (10 Digits only)"
                  value={data.phone}
                  onChange={(e) => {
                    setData({ ...data, phone: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Your Query (Limit to 60 words)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={data.message}
                  onChange={(e) => {
                    setData({ ...data, message: e.target.value });
                  }}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!formSubmitted && (
            <Button variant="primary" onClick={handleSubmit}>
              Book Appointment
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
