import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/esm/Col";
import { useEffect, useState } from "react";
import { getContent, getStatsList } from "../../api/api";

const CountingStatistics = ({ value }) => {
  const [count, setCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("abif-stats");
      if (element && !isCounting) {
        const topPosition = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (topPosition < windowHeight) {
          setIsCounting(true);
          startCounting();
        }
      }
    };
    const startCounting = () => {
      const targetValue = parseFloat(value.replace(/[^\d.]/g, "")); // Remove non-numeric characters
      const duration = 1400; // Duration of the animation in milliseconds
      const step = ((targetValue - 0) / duration) * 10; // Increment step (adjust as needed)

      let animationFrame;
      let start;

      const animateCount = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const nextCount = Math.min(
          // Math.round((progress / 10) * step),
          // ((progress / 10) * step).toFixed(2),
          Number.isInteger(targetValue)
            ? Math.round((progress / 10) * step)
            : ((progress / 10) * step).toFixed(2),
          targetValue
        );

        setCount(nextCount);

        if (progress < duration) {
          animationFrame = requestAnimationFrame(animateCount);
        }
      };

      animationFrame = requestAnimationFrame(animateCount);

      return () => cancelAnimationFrame(animationFrame);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [value, isCounting]);

  return (
    <p className="home__impact--stats--number">
      {value.replace(/\d{1,3}(?:,\d{3})*(?:\.\d+)?/g, count.toLocaleString())}
    </p>
  );
};

export default function OurImpact() {
  const [list, setList] = useState([]);
  useEffect(() => {
    getStatsList().then((result) => {
      setList(result);
    });
  }, []);
  return (
    <>
      <div className="home__impact">
        <Container>
          {/* <Row>
            <h2 className="heading--primary">
              Our Impact on the Agri Business and Rural Developmet Ecosystem
            </h2>
          </Row> */}
          <Row className="home__impact--stats" id="abif-stats">
            <h2
              className="heading--primary wow animate__animated animate__fadeInUp"
              style={{ padding: 0 }}
            >
              Our Impact
              {/* Our Impact on the Agri Business and Rural Developmet Ecosystem */}
            </h2>
            {list.slice(0, 6).map((item) => (
              <Col md={4} className="home__impact--stats--div">
                {/* <p className="home__impact--stats--number">{item.number}</p> */}
                <CountingStatistics value={item.number} />
                <p className="home__impact--stats--title">{item.title}</p>
              </Col>
            ))}
          </Row>
          {/* <Row className="home__impact--stats">
            {list.slice(3, 6).map((item) => (
              <Col md={4} className="home__impact--stats--div">
                <p className="home__impact--stats--number">{item.number}</p>
                <p className="home__impact--stats--title">{item.title}</p>
              </Col>
            ))} */}
          {/* </Row> */}
        </Container>
      </div>
    </>
  );
}
