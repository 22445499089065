import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { getCarouselList } from "../../api/api";

export default function MainCarousel() {
  const [list, setList] = useState([]);
  useEffect(() => {
    getCarouselList().then((result) => {
      setList(result);
    });
  }, []);

  return (
    <Carousel className="carousel__main">
      {list.map((item, index) => (
        <Carousel.Item>
          <img className="d-block w-100" src={item.image} alt="slide" />
          {/* <Carousel.Caption>
            <h3>{item.title}</h3>
            <p>{item.subtitle}</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
