import MainCarousel from "../components/Carousel/MainCarousel";
import RecentList from "../components/List/RecentList";
import OurStory from "../components/Home/OurStory";
import SocialMediaPosts from "../components/Home/SocialMediaPosts";
import OurImpact from "../components/Home/OurImpact";
import UpcomingEvents from "../components/Home/UpcomingEvents";

export default function Home() {
  return (
    <>
      <MainCarousel />
      <OurStory />
      <RecentList />
      <UpcomingEvents display={"incomplete"} />
      <OurImpact />
      <SocialMediaPosts />
    </>
  );
}
