import { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { subcribeNewsletter } from "../../api/api";

export default function Subscribe() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("pending");
  const handleSubmit = () => {
    if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setStatus("loading");
      subcribeNewsletter(email)
        .then((result) => {
          setEmail("");
          setStatus("success");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Enter a Valid Email!");
    }
  };
  return (
    <Container className="home__subscribe">
      <Row>
        <h3 className="heading--primary" style={{ padding: 0 }}>
          Join the Agri Business Revolution!
        </h3>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <p className="home__subscribe--content">
            Subscribe to our mailing list now
          </p>
        </Col>
        {status === "pending" ? (
          <Col sm={12} md={12} className="home__subscribe--main">
            <input
              className="home__subscribe--input"
              type="email"
              name="email"
              placeholder="youremail@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="home__subscribe--button button--primary"
              onClick={handleSubmit}
            >
              Subscribe
            </button>
          </Col>
        ) : status === "loading" ? (
          <Col sm={12} md={12}>
            <p className="home__subscribe--content">Storing your email...</p>
          </Col>
        ) : status === "success" ? (
          <Col sm={12} md={12}>
            <p className="home__subscribe--content">
              Subscribe Successfully...
            </p>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  );
}
