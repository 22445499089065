import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

export default function MemberCard({ member }) {
  return (
    <Row className="card__member wow animate__animated animate__fadeInUp">
      <Col sm={12} md={4} lg={3}>
        {member?.image && (
          <img
            src={member?.image}
            alt="Member"
            className="card__member--image"
          />
        )}
      </Col>
      <Col sm={12} md={8} lg={9} className="card__member--text">
        <p className="card__member--name">{member?.name}</p>
        <p className="card__member--title">{member?.position}</p>
        <p className="card__member--info">{member?.info}</p>
      </Col>
    </Row>
  );
}
