import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { useEffect, useState } from "react";
import { getPortfolioList } from "../api/api";

export default function Portfolio() {
  const [data, setData] = useState({ startUps: [], mentors: [] });
  useEffect(() => {
    Promise.all([getPortfolioList("startup"), getPortfolioList("mentor")]).then(
      (result) => {
        setData({ ...data, startUps: result[0], mentors: result[1] });
      }
    );
  }, [data]);
  return (
    <>
      <Container className="portfolio">
        <Row>
          <h2 className="heading--primary">Start-Ups</h2>
        </Row>
        <Row className="portfolio--main">
          {data.startUps?.slice(0, 4).map((item) => (
            <Col md={4} lg={3}>
              <img
                src={item.logo}
                className="portfolio--image"
                alt="portfolio"
              />
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="portfolio">
        <Row>
          <h2 className="heading--primary">Mentors</h2>
        </Row>
        <Row className="portfolio--main">
          {data.mentors?.slice(0, 4).map((item) => (
            <Col md={4} lg={3}>
              <img
                src={item.logo}
                className="portfolio--image"
                alt="portfolio"
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}
