import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { useNavigate, useParams } from "react-router-dom";
import { getFPO } from "../api/api";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

export default function FPOInfo() {
  const [fpo, setFPO] = useState({});
  const { fpoId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (fpoId) {
      getFPO(fpoId).then((result) => {
        if (result.length === 0) {
          navigate("/fpo");
        } else {
          setFPO(result[0]);
        }
      });
    }
  }, [fpoId]);
  return (
    <>
      <Container style={{ paddingTop: "4%", paddingBottom: "4%" }}>
        <p style={{ fontSize: "2em", marginBottom: 0 }}>{fpo.name}</p>
        <p style={{ marginBottom: 0 }}>
          {fpo.district} ({fpo.state})
        </p>
        {fpo.registration_no && <p>Regn No: {fpo.registration_no}</p>}
        <Row style={{ textAlign: "left" }}>
          <Col sm={12} md={9}>
            <p>{fpo.description}</p>
            {fpo.no_of_farmers && (
              <p style={{ fontSize: "1.6em", marginBottom: 0 }}>
                No. of Farmers: {fpo.no_of_farmers}
              </p>
            )}
            <p style={{ fontSize: "1.6em", marginBottom: 0 }}>
              {/* Details of Operation/activities: */}
              Operations/Commodities dealing with:
            </p>
            <ul>
              {fpo.operations &&
                JSON.parse(fpo.operations)?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
            </ul>
            <p style={{ fontSize: "1.6em", marginBottom: 0 }}>MD/CEO:</p>
            <ul>
              {fpo.people &&
                JSON.parse(fpo.people)?.map((item, index) => (
                  <li key={index}>
                    <span style={{ fontSize: "1.3em" }}>{item.name}</span>{" "}
                    <br />
                    {item.info}
                    <br />
                    {item.description}
                  </li>
                ))}
            </ul>
            {/* <p style={{ fontSize: "1.6em", marginBottom: 0 }}>
            Support Required:
          </p>
          <ul>
            {fpo.support_required &&
              JSON.parse(fpo.support_required)?.map((item, index) => (
                <li key={index}>
                  <span style={{ fontSize: "1.3em" }}>{item.name}</span> <br />
                  {item.info}
                  <br />
                  {item.description}
                </li>
              ))}
          </ul> */}
            {/* <p style={{ fontSize: "1.6em", marginBottom: 0 }}>Contact Details</p>
          <div>
            {fpo.contact && JSON.parse(fpo.contact).email && (
              <p>Email: {JSON.parse(fpo.contact).email}</p>
            )}
            {fpo.contact && JSON.parse(fpo.contact).phone && (
              <p>Phone Number: {JSON.parse(fpo.contact).phone}</p>
            )}
            {fpo.contact && JSON.parse(fpo.contact).website && (
              <p>Website: {JSON.parse(fpo.contact).website}</p>
            )}
            {fpo.contact && JSON.parse(fpo.contact).address && (
              <p>Address: {JSON.parse(fpo.contact).address}</p>
            )}
          </div> */}
          </Col>
          <Col sm={12} md={3}>
            {fpo.logo && (
              <img
                src={fpo.logo}
                style={{
                  width: "100%",
                  borderRadius: "2em",
                  border: "1px solid black",
                  marginBottom: "1em",
                }}
                alt="FPO"
              />
            )}
            {/* {fpo.contact && JSON.parse(fpo.contact).website && (
              <a href={JSON.parse(fpo.contact).website} target="_blank">
                <Button className="w-100 button--primary">Visit Website</Button>
              </a>
            )} */}
          </Col>
        </Row>
      </Container>
    </>
  );
}
