import { useEffect, useState } from "react";
import { getStartupRecruitmentList } from "../api/api";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Table } from "react-bootstrap";

export default function Recruitment() {
  const [list, setList] = useState([]);
  useEffect(() => {
    getStartupRecruitmentList().then((result) => setList(result));
  }, []);
  return (
    <>
      <Container className="notice">
        <Row>
          <Col>
            <h2 className="heading--primary">Recruitment in Startups</h2>
          </Col>
        </Row>
        {list && list.length > 0 && (
          <Row>
            <Table striped bordered hover responsive>
              <thead>
                <tr className="wow animate__animated animate__fadeInUp">
                  <th>S.No.</th>
                  <th>Recruitment Type</th>
                  <th>Startup Name</th>
                  <th>Title</th>
                  <th>Publish Date</th>
                  <th>Last Date</th>
                  <th>Links</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr
                    key={index + 1}
                    className="wow animate__animated animate__fadeInUp"
                  >
                    <td>{index + 1}</td>
                    <td>{item.recruitment_type}</td>
                    <td>{item.startup_name}</td>
                    <td>{item.title}</td>
                    <td>{new Date(item.publish_date).toLocaleString()}</td>
                    <td>{new Date(item.last_date).toLocaleString()}</td>
                    <td>
                      {item.advertisement && (
                        <a
                          href={item.advertisement}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Advertisement
                        </a>
                      )}
                      {item.link && (
                        <>
                          <br />
                          <a href={item.link} target="_blank" rel="noreferrer">
                            Application
                          </a>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
}
