import { useEffect, useState } from "react";
import { getNotices } from "../api/api";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

export default function Notice({ display }) {
  const [list, setList] = useState([]);
  useEffect(() => {
    getNotices(display).then((result) => setList(result));
  }, [display]);
  return (
    <>
      <Container className="notice">
        <Row>
          <Col>
            <h2 className="heading--primary">
              {display === "tender" ? "Tender" : "Recruitment"}
            </h2>
          </Col>
        </Row>
        {list && list.length > 0 && (
          <Row>
            <Table striped bordered hover responsive>
              <thead>
                <tr className="wow animate__animated animate__fadeInUp">
                  <th>S.No.</th>
                  <th>Notice No.</th>
                  <th>Title</th>
                  <th>Publish Date</th>
                  <th>Last Date</th>
                  <th>
                    {display === "tender" ? "Advertisement" : "Document(s)"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr
                    key={index + 1}
                    className="wow animate__animated animate__fadeInUp"
                  >
                    <td>{index + 1}</td>
                    <td>{item.notice_no}</td>
                    <td>{item.title}</td>
                    <td>{new Date(item.publish_date).toLocaleString()}</td>
                    <td>{new Date(item.last_date).toLocaleString()}</td>
                    <td>
                      <a
                        href={item.advertisement}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {display === "tender" ? "Open File" : "Advertisement"}
                      </a>
                      {item.link && (
                        <>
                          <br />
                          <a href={item.link} target="_blank" rel="noreferrer">
                            Application
                          </a>
                        </>
                      )}
                      {item.files &&
                        JSON.parse(item.files)?.map((rec, recIndexx) => (
                          <>
                            <br />
                            <a href={rec.link} target="_blank" rel="noreferrer">
                              {rec.title}
                            </a>
                          </>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </Container>
    </>
  );
}
