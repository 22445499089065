import { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import { useNavigate, useParams } from "react-router-dom";
import { getStartup } from "../api/api";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

export default function StartupInfo() {
  const [startup, setStartup] = useState({});
  const { startupId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (startupId) {
      getStartup(startupId).then((result) => {
        if (result.length === 0) {
          navigate("/startups");
        } else {
          setStartup(result[0]);
        }
      });
    }
  }, [startupId]);
  return (
    <>
      <Container style={{ paddingTop: "4%", paddingBottom: "4%" }}>
        <p style={{ fontSize: "2em", marginBottom: 0 }}>{startup.name}</p>
        {startup.cin && <p>CIN: {startup.cin}</p>}
        <Row style={{ textAlign: "left" }}>
          <Col sm={12} md={9}>
            <p>{startup.description}</p>
            <p style={{ fontSize: "1.6em", marginBottom: 0 }}>
              Domain/Area of Business:
            </p>
            <ul>
              {startup.domain &&
                JSON.parse(startup.domain)?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
            </ul>
            <p style={{ fontSize: "1.6em", marginBottom: 0 }}>
              Founder/Co-Founder:
            </p>
            <ul>
              {startup.people &&
                JSON.parse(startup.people)?.map((item, index) => (
                  <li key={index}>
                    <span style={{ fontSize: "1.3em" }}>{item.name}</span>{" "}
                    <br />
                    {item.info}
                    <br />
                    {item.description}
                  </li>
                ))}
            </ul>
            {/* <p style={{ fontSize: "1.6em", marginBottom: 0 }}>Mentored by:</p>
          <ul>
            <li>
              <span style={{ fontSize: "1.3em" }}>Mentor Name</span> <br />
              Mentor Qualifications
              <br />
              Mentor Info (3-4Lines)
            </li>
            <li>
              <span style={{ fontSize: "1.3em" }}>Mentor Name</span> <br />
              Mentor Qualifications
              <br />
              Mentor Info (3-4Lines)
            </li>
          </ul> */}
            {/* <p style={{ fontSize: "1.6em", marginBottom: 0 }}>Contact Details:</p>
          <div>
            {startup.contact && JSON.parse(startup.contact).email && (
              <p>Email: {JSON.parse(startup.contact).email}</p>
            )}
            {startup.contact && JSON.parse(startup.contact).phone && (
              <p>Phone Number: {JSON.parse(startup.contact).phone}</p>
            )}
            {startup.contact && JSON.parse(startup.contact).website && (
              <p>Website: {JSON.parse(startup.contact).website}</p>
            )}
            {startup.contact && JSON.parse(startup.contact).address && (
              <p>Address: {JSON.parse(startup.contact).address}</p>
            )}
          </div> */}
          </Col>
          <Col sm={12} md={3}>
            <img
              src={startup.logo}
              style={{
                width: "100%",
                borderRadius: "2em",
                border: "1px solid black",
                marginBottom: "1em",
              }}
              alt="Startup"
            />
            {/* {startup.contact && JSON.parse(startup.contact).website && (
              <a href={JSON.parse(startup.contact).website} target="_blank">
                <Button className="w-100 button--primary">Visit Website</Button>
              </a>
            )} */}
          </Col>
        </Row>
      </Container>
    </>
  );
}
