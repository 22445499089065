import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import { useEffect, useState } from "react";
import { getFPOList } from "../api/api";

export default function FPO() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    getFPOList().then((result) => {
      setData(result);
    });
  }, []);
  const itemsPerPage = 24;
  return (
    <>
      <Container className="startup">
        <Row>
          <h2 className="heading--primary">FPOs</h2>
        </Row>
        <Row>
          {data
            ?.slice(
              (currentPage - 1) * itemsPerPage,
              currentPage * itemsPerPage
            )
            ?.map((item, index) => (
              <Col
                sm={12}
                md={4}
                style={{
                  alignItems: "stretch",
                  marginBottom: "1em",
                }}
                key={item.id}
              >
                <Card
                  style={
                    {
                      // height: "100%",
                    }
                  }
                  className="wow animate__animated animate__fadeInUp"
                >
                  <Card.Body className="">
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>
                      {item.district} ({item.state})
                      <p>
                        <a href={`/fpo/${item.slug}`}>View More</a>
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "max-content",
            margin: "0 auto",
          }}
        >
          <Pagination style={{ alignItems: "center", textAlign: "center" }}>
            {Array.from(
              { length: Math.ceil(data.length / itemsPerPage) },
              (_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePagination(index + 1)}
                  // className="button--primary"
                >
                  {index + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
        </Row>
      </Container>
    </>
  );
}
