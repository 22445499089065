import { Routes, Route } from "react-router-dom";
import "./App.css";
import "animate.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import BoardOfDirectors from "./pages/About/BoardOfDirectors";
import Notice from "./pages/Notice";
import PMRC from "./pages/About/PMRC";
import Team from "./pages/About/Team";
import OurStory from "./pages/About/OurStory";
import ContactUs from "./pages/ContactUs";
import Event from "./pages/Event";
import Apply from "./pages/Apply";
import ChairmansMessage from "./pages/About/ChairmansMessage";
import ApplyMentor from "./pages/Apply/ApplyMentor";
import ApplyIncubation from "./pages/Apply/ApplyIncubation";
import Program from "./pages/Program";
import Mentors from "./pages/Mentors";
import Startups from "./pages/Startup";
import ApplyRecruitment from "./pages/Apply/ApplyRecruitment";
import { useEffect } from "react";
import Loader from "./components/Loader";
import { useState } from "react";
import WOW from "wow.js";
import MentorInfo from "./pages/MentorInfo";
import StartupInfo from "./pages/StartupInfo";
import FPOInfo from "./pages/FPOInfo";
import FPO from "./pages/FPO";
import IncubateeExpo2024 from "./pages/Programs/IncubateeExpo2024";
import Recruitment from "./pages/Recruitment";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    new WOW({
      live: false,
    }).init();
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="App">
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route path="/about">
              <Route index element={<OurStory />} />
              <Route
                exact
                path="board-of-directors"
                element={<BoardOfDirectors />}
              />
              <Route
                exact
                path="chairman's-message"
                element={<ChairmansMessage />}
              />
              <Route exact path="pmrc" element={<PMRC />} />
              <Route exact path="team" element={<Team />} />
            </Route>
            <Route
              exact
              path="/tenders"
              element={<Notice display={"tender"} />}
            />
            <Route
              exact
              path="/recruitment-in-startups"
              element={<Recruitment />}
            />
            <Route
              exact
              path="/recruitment"
              element={<Notice display={"recruitment"} />}
            />
            <Route exact path="/contact" element={<ContactUs />} />
            <Route exact path="/events" element={<Event />} />
            <Route exact path="/program" element={<Program />} />
            <Route exact path="/apply" element={<Apply />} />
            <Route exact path="/apply/mentor" element={<ApplyMentor />} />
            <Route
              exact
              path="/apply/incubation"
              element={<ApplyIncubation />}
            />
            <Route
              exact
              path="/apply/recruitment"
              element={<ApplyRecruitment />}
            />
            <Route exact path="/mentors" element={<Mentors />} />
            <Route exact path="/mentors/:mentorId" element={<MentorInfo />} />
            <Route exact path="/startups" element={<Startups />} />
            <Route
              exact
              path="/startups/:startupId"
              element={<StartupInfo />}
            />
            <Route exact path="/fpo/:fpoId" element={<FPOInfo />} />
            <Route exact path="/fpo" element={<FPO />} />
            <Route
              exact
              path="/program/incubatees-expo-2024"
              element={<IncubateeExpo2024 />}
            />
          </Routes>
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
