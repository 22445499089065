import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import { getEventList } from "../../api/api";
import { useEffect } from "react";

export default function UpcomingEvents({ display }) {
  // const [list, setList] = useState([
  //   {
  //     date: "2020-12-12",
  //     title: "Startup-FPO Immersion Program",
  //     image: "/assets/images/2.png",
  //     content: `The Startup-Farmer Producer Organization (FPO) Immersion Program by ABIF IIT Kharagpur is a pioneering initiative fostering the integration of startups with rural agricultural communities. This program enables startups to collaborate with FPOs, bridging the gap between innovative solutions and traditional farming practices. Through hands-on experiences and mentorship, entrepreneurs gain a deeper understanding of rural challenges and opportunities, paving the way for sustainable and impactful agricultural innovations.`,
  //     buttons: [
  //       {
  //         text: "Register as FPO/Farmer",
  //         link: "https://forms.gle/aJwa737g2G3YMAeJ7",
  //       },
  //       {
  //         text: "Register as Startup",
  //         link: "https://forms.gle/dXJntKBNVdKhv3Eg8",
  //       },
  //     ],
  //     images: [
  //       "/assets/images/4.png",
  //       "/assets/images/2.png",
  //       "/assets/images/3.png",
  //     ],
  //   },
  //   {
  //     date: "2020-12-12",
  //     image: "/assets/images/5.png",
  //     title: "Krishi Uddyam: Secret Sauce of successful entrepreneurs",
  //     content: `Krishi Udyam - Secret Sauce of Successful Agripreneurs' is an online series event focused to stimulate agripreneurship & business skills among rural women, unemployed youth and progressive farmers by organizing talks / presentations from successful entrepreneurs who are working for farmers and transforming rural lives.`,
  //     buttons: [
  //       {
  //         text: "Register as Participants",
  //         link: "#",
  //       },
  //       {
  //         text: "Register as Speaker",
  //         link: "#",
  //       },
  //     ],
  //     images: ["/assets/images/6.png", "/assets/images/5.png"],
  //   },
  // ]);
  const [list, setList] = useState([]);
  useEffect(() => {
    getEventList().then((result) => {
      setList(result);
    });
  }, []);
  return (
    <Container className="home__upcoming">
      <Row>
        <h2 className="heading--primary">Upcoming Events</h2>
      </Row>
      <Row
        className="home__upcoming--main"
        style={{ alignItems: "stretch", alignContent: "stretch" }}
      >
        {list
          .filter((item, index) => index >= 0 && index <= 3)
          .map((item, index) => (
            <Col
              sm={12}
              md={6}
              lg={6}
              style={{
                alignItems: "stretch",
              }}
            >
              <Card
                className="home__upcoming--card wow animate__animated animate__fadeInUp "
                style={{ alignSelf: "stretch", height: "100%" }}
              >
                <Carousel className="">
                  {[item.image, item.image2, item.image3]?.map((rec, index) => (
                    <Carousel.Item>
                      <img className="d-block w-100" src={rec} alt="slide" />
                    </Carousel.Item>
                  ))}
                </Carousel>
                {/* <Card.Img variant="top" src={item.image} /> */}
                <Card.Body>
                  <Card.Title className="home__upcoming--title">
                    {item.title}
                  </Card.Title>
                  <Card.Text
                    className={
                      display === "complete"
                        ? "home__upcoming--content"
                        : "home__upcoming--content--hide"
                    }
                    style={{ marginBottom: "auto" }}
                  >
                    {/* [{item.date}] */}
                    {item.description}
                  </Card.Text>
                  {display === "incomplete" && (
                    <Card.Link href="/events">
                      <Button
                        variant="primary"
                        className="mt-2 button--primary"
                      >
                        Know More
                      </Button>
                    </Card.Link>
                  )}
                  {JSON.parse(item.buttons)?.map((rec) => (
                    <Card.Link href={rec.link}>
                      <Button
                        variant="primary"
                        className="mt-2 button--primary"
                      >
                        {rec.text}
                      </Button>
                    </Card.Link>
                  ))}
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
}
