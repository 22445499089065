import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import { getNotices } from "../../api/api";
import { useEffect } from "react";

let myInitialValues = {
  position: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  gender: "",
  dob: "",
  email: "",
  email_alternate: "",
  phone: "",
  category: "",
  marital_status: "",
  nationality: "",
  id_proof: "",
  id_proof_number: "",
  physically_handicapped: "",
  ex_servicemen: "",
  current_address: "",
  current_address_city: "",
  current_address_district: "",
  current_address_state: "",
  current_address_country: "",
  current_address_pincode: "",
  permanent_address: "",
  permanent_address_city: "",
  permanent_address_district: "",
  permanent_address_state: "",
  permanent_address_country: "",
  permanent_address_pincode: "",
  birth_city: "",
  birth_district: "",
  birth_state: "",
  education: [
    {
      educational_level: "",
      degree: "",
      discipline: "",
      institute: "",
      university: "",
      year: "",
      division: "",
      grading: "",
      average: "",
      total: "",
      rank: "",
    },
  ],
  experience: [
    {
      organization: "",
      designation: "",
      from_date: "",
      to_date: "",
      pay_scale: "",
      basic_pay: "",
      grade_pay: "",
      total_pay: "",
      experience: "",
    },
  ],
  additional_remarks: "",
  photo: null,
  signature: null,
  age_proof: null,
  id_proof_document: null,
  additional_document: null,
  category_document: null,
  educational_document: null,
  experience_document: null,
};

export default function ApplyRecruitment() {
  const [data, setData] = useState(myInitialValues);
  const [preview, setPreview] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    getNotices("recruitment").then((result) => {
      setList(
        result.filter(
          (item) =>
            new Date(item.publish_date) < Date.now() &&
            new Date(item.last_date) > Date.now() &&
            parseInt(item.status) === 1
        )
      );
    });
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (data.phone.length !== 10) {
      alert("Enter a valid Phone number(10 Digits");
      return;
    } else if (!data.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      alert("Enter a valid Email");
      return;
    } else if (
      !data.email_alternate ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email_alternate)
    ) {
      alert("Enter a valid alternate Email");
      return;
    } else if (data.email === data.email_alternate) {
      alert("Email Id and Alternative Email Id should be different.");
      return;
    } else if (
      data.additional_remarks
        .trim()
        .split(" ")
        .filter((word) => word !== "").length >= 250
    ) {
      alert("Summary should be less than 200 words.");
      return;
    } else if (
      data.current_address_pincode.length !== 6 ||
      data.permanent_address_pincode.length !== 6
    ) {
      alert("Pincode lenght must be 6.");
      return;
    }
    if (form.checkValidity() === false) {
      // event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      alert("Fill all required field.");
      return;
    } else {
      setLoading(true);
      let fd = new FormData();
      fd.append("position", data.position);
      fd.append("first_name", data.first_name);
      fd.append("middle_name", data.middle_name);
      fd.append("last_name", data.last_name);
      fd.append("gender", data.gender);
      fd.append("dob", data.dob);
      fd.append("phone", data.phone);
      fd.append("email", data.email);
      fd.append("email_alternate", data.email_alternate);
      fd.append("category", data.category);
      fd.append("marital_status", data.marital_status);
      fd.append("nationality", data.nationality);
      fd.append("id_proof", data.id_proof);
      fd.append("id_proof_number", data.id_proof_number);
      fd.append("physically_handicapped", data.physically_handicapped);
      fd.append("ex_servicemen", data.ex_servicemen);
      fd.append("current_address", data.current_address);
      fd.append("current_address_city", data.current_address_city);
      fd.append("current_address_district", data.current_address_district);
      fd.append("current_address_state", data.current_address_state);
      fd.append("current_address_country", data.current_address_country);
      fd.append("current_address_pincode", data.current_address_pincode);
      fd.append("permanent_address", data.permanent_address);
      fd.append("permanent_address_city", data.permanent_address_city);
      fd.append("permanent_address_district", data.permanent_address_district);
      fd.append("permanent_address_state", data.permanent_address_state);
      fd.append("permanent_address_country", data.permanent_address_country);
      fd.append("permanent_address_pincode", data.permanent_address_pincode);
      fd.append("birth_city", data.birth_city);
      fd.append("birth_district", data.birth_district);
      fd.append("birth_state", data.birth_state);
      fd.append("education", JSON.stringify(data.education));
      fd.append("experience", JSON.stringify(data.experience));
      fd.append("additional_remarks", data.additional_remarks || "");
      fd.append("photo", data.photo);
      fd.append("signature", data.signature);
      fd.append("age_proof", data.age_proof);
      fd.append("id_proof_document", data.id_proof_document);
      fd.append("educational_document", data.educational_document);
      fd.append("experience_document", data.experience_document);
      if (data.additional_document) {
        fd.append("additional_document", data.additional_document);
      }
      if (data.category_document) {
        fd.append("category_document", data.category_document);
      }
      try {
        fetch(`${process.env.REACT_APP_BASE_URL}/apply/recruitment`, {
          headers: {
            Accept: "application/json",
          },
          method: "POST",
          body: fd,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status === "success") {
              setValidated(true);
              setFormSubmitted(true);
            }
            setLoading(false);
          });
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Container className="apply">
        <h2 className="heading--primary">
          Application for Recruitment at <br /> Agri Business Incubation
          Foundation IIT Kharagpur
        </h2>
        {formSubmitted ? (
          <h4 className="heading-primary" style={{ padding: "2em 0" }}>
            Thank you for registering yourself for Incubation at ABIF!
            <br />
            We will get back to you shortly.
          </h4>
        ) : (
          <div className="apply--info">
            <p>
              Applications are invited from startups / ideas in agriculture and
              allied sector especially Precision Farming, Nanotechnology, AI /
              Machine Learning / IoT in Agriculture and other technology based
              agribusinesses / ideas, for getting incubation support under Agri
              Business Incubation Foundation IIT Kharagpur.
            </p>
            <p>
              For any queries, please feel free to write to{" "}
              <a href="mailto:info@abifiitkgp.com">
                info@abifiitkgp.com
              </a>
            </p>
          </div>
        )}
      </Container>
      <Container className="">
        {!formSubmitted && (
          <Form
            className="apply-form"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            {/* Position */}
            <Row className="mt-3">
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Position:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      disabled={preview}
                      plaintext={preview}
                      readOnly={preview}
                      name={"position"}
                      required={true}
                      value={data.position}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      // style={{ color: "#D3D3D3 !important" }}
                    >
                      <option value={""} disabled>
                        Select Position
                      </option>
                      {list?.map((item) => (
                        <option value={item.title} key={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"text"}
                      placeholder={"Position you want to apply for..."}
                      name={"position"}
                      required={true}
                      value={data.position}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    /> */}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* Section A Heading */}
            <Row className="">
              <Col sm={12}>
                <h3 className="apply--section--heading">
                  A. Personal Information:
                </h3>
              </Col>
            </Row>
            <Row>
              {/* Name */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Full Name:
                  </Form.Label>
                  <Col sm={12} md={3} lg={3}>
                    <Form.Control
                      type={"text"}
                      placeholder={"First Name"}
                      name={"first_name"}
                      required={true}
                      value={data.first_name}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      plaintext={preview}
                      readOnly={preview}
                    />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Middle Name"}
                      name={"middle_name"}
                      required={true}
                      value={data.middle_name}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      plaintext={preview}
                      readOnly={preview}
                    />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <Form.Control
                      type={"text"}
                      placeholder={"Last Name"}
                      name={"last_name"}
                      required={true}
                      value={data.last_name}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      plaintext={preview}
                      readOnly={preview}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Gender */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Gender:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      disabled={preview}
                      plaintext={preview}
                      readOnly={preview}
                      name={"gender"}
                      required={true}
                      value={data.gender}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      // style={{ color: "#D3D3D3 !important" }}
                    >
                      <option value={""} disabled>
                        Select Gender
                      </option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                      <option value={"others"}>Others</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              {/* Date of Birth */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Date of Birth:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"date"}
                      placeholder={"Date of Birth"}
                      name={"dob"}
                      required={true}
                      value={data.dob}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Email Id */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Email Id:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"email"}
                      placeholder={"your_email@email.com"}
                      name={"email"}
                      required={true}
                      value={data.email}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Alternate Email Id */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Alterantive Email Id:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"email"}
                      placeholder={"your_email_alternative@email.com"}
                      name={"email_alternate"}
                      required={true}
                      value={data.email_alternate}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Phone Number */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Phone Number:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"text"}
                      placeholder={"Phone Number"}
                      name={"phone"}
                      required={true}
                      value={data.phone}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Category */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Category:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      disabled={preview}
                      plaintext={preview}
                      readOnly={preview}
                      name={"category"}
                      required={true}
                      value={data.category}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      // style={{ color: "#D3D3D3 !important" }}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value={"General"}>General</option>
                      <option value={"EWS"}>EWS</option>
                      <option value={"OBC"}>OBC</option>
                      <option value={"SC"}>SC</option>
                      <option value={"ST"}>ST</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              {/* Marital Status */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Marital Status:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      disabled={preview}
                      plaintext={preview}
                      readOnly={preview}
                      name={"marital_status"}
                      required={true}
                      value={data.marital_status}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      // style={{ color: "#D3D3D3 !important" }}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value={"Married"}>Married</option>
                      <option value={"Single"}>Single</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              {/* Nationality */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Nationality:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"text"}
                      placeholder={"Nationality"}
                      name={"nationality"}
                      required={true}
                      value={data.nationality}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* ID Proof */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    ID Proof:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      disabled={preview}
                      plaintext={preview}
                      readOnly={preview}
                      name={"id_proof"}
                      required={true}
                      value={data.id_proof}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value={"Passport"}>Passport</option>
                      <option value={"Voter ID"}>Voter ID</option>
                      <option value={"Aadhaar"}>Aadhaar</option>
                      <option value={"PanCard"}>PanCard</option>
                      <option value={"Service ID"}>Service ID</option>
                    </Form.Select>
                    {data.id_proof !== "" && (
                      <Form.Control
                        plaintext={preview}
                        readOnly={preview}
                        type="text"
                        name={"id_proof_number"}
                        placeholder="ID Number"
                        required={false}
                        value={data.id_proof_number}
                        onChange={(e) => {
                          setData({ ...data, [e.target.name]: e.target.value });
                        }}
                        className="mt-2"
                      />
                    )}
                  </Col>
                </Form.Group>
              </Col>
              {/* Physically Handicapped */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Are you Physically Handicapped?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      disabled={preview}
                      plaintext={preview}
                      readOnly={preview}
                      name={"physically_handicapped"}
                      required={true}
                      value={data.physically_handicapped}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      // style={{ color: "#D3D3D3 !important" }}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              {/* Ex-servicemen */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Are you Ex-servicemen?:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Select
                      disabled={preview}
                      plaintext={preview}
                      readOnly={preview}
                      name={"ex_servicemen"}
                      required={true}
                      value={data.ex_servicemen}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                      // style={{ color: "#D3D3D3 !important" }}
                    >
                      <option value={""} disabled>
                        Select
                      </option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              {/* Address */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Current Address:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type="text"
                      placeholder={"Current Address"}
                      name={"current_address"}
                      required={true}
                      value={data.current_address}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                    <Row>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"City/Town/Village"}
                          name={"current_address_city"}
                          required={true}
                          value={data.current_address_city}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"District"}
                          name={"current_address_district"}
                          required={true}
                          value={data.current_address_district}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"State"}
                          name={"current_address_state"}
                          required={true}
                          value={data.current_address_state}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"Country"}
                          name={"current_address_country"}
                          required={true}
                          value={data.current_address_country}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"Pin Code"}
                          name={"current_address_pincode"}
                          required={true}
                          value={data.current_address_pincode}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Permanent Address:
                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          permanent_address: data.current_address,
                          permanent_address_city: data.current_address_city,
                          permanent_address_district:
                            data.current_address_district,
                          permanent_address_state: data.current_address_state,
                          permanent_address_country:
                            data.current_address_country,
                          permanent_address_pincode:
                            data.current_address_pincode,
                        });
                      }}
                      className="button--primary"
                    >
                      Same as above
                    </Button>
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type="text"
                      placeholder={"Permanent Address"}
                      name={"permanent_address"}
                      required={true}
                      value={data.permanent_address}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                    <Row>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"City/Town/Village"}
                          name={"permanent_address_city"}
                          required={true}
                          value={data.permanent_address_city}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"District"}
                          name={"permanent_address_district"}
                          required={true}
                          value={data.permanent_address_district}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"State"}
                          name={"permanent_address_state"}
                          required={true}
                          value={data.permanent_address_state}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"Country"}
                          name={"permanent_address_country"}
                          required={true}
                          value={data.permanent_address_country}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mt-2">
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type="text"
                          placeholder={"Pin Code"}
                          name={"permanent_address_pincode"}
                          required={true}
                          value={data.permanent_address_pincode}
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
              {/* Place of Birth */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Birth Place:
                  </Form.Label>
                  <Col sm={12} md={3} lg={3}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"text"}
                      placeholder={"City/Town/Village"}
                      name={"birth_city"}
                      required={true}
                      value={data.birth_city}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"text"}
                      placeholder={"District"}
                      name={"birth_district"}
                      required={true}
                      value={data.birth_district}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                  <Col sm={12} md={3} lg={3}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      type={"text"}
                      placeholder={"Birth State"}
                      name={"birth_state"}
                      required={true}
                      value={data.birth_state}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* Section B Heading */}
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">
                  B. Educational Details:{" "}
                  <Button
                    disabled={preview}
                    onClick={() => {
                      setData({
                        ...data,
                        education: [
                          ...data.education,
                          {
                            educational_level: "",
                            degree: "",
                            discipline: "",
                            institute: "",
                            university: "",
                            year: "",
                            division: "",
                            grading: "",
                            average: "",
                            total: "",
                            rank: "",
                          },
                        ],
                      });
                    }}
                    className="button--primary"
                  >
                    Add New Row
                  </Button>
                </h3>
              </Col>
            </Row>
            {/* Education Table */}
            <Row>
              <Table bordered responsive size="lg">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Educational Level</th>
                    <th>Degree/Examination</th>
                    <th>Discilpine</th>
                    <th>College/University</th>
                    <th>Affiliated University/Board/Autonomus Body</th>
                    <th>Passing Year</th>
                    <th>Division/Class</th>
                    <th>Percentage/GPA</th>
                    <th>Average Marks/GPA</th>
                    <th>Total Marks/GPA</th>
                    <th>Rank, if any(In Board University)</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.education?.map((item, index) => (
                    <tr>
                      <td>{index + 1}.</td>
                      <td>
                        <Form.Select
                          disabled={preview}
                          plaintext={preview}
                          readOnly={preview}
                          style={{ minWidth: "max-content" }}
                          name={"educational_level"}
                          required={true}
                          value={item.educational_level}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        >
                          <option value={""} disabled>
                            Select
                          </option>
                          <option value={"Others"}>Others</option>
                          <option value={"D.Sc/Equivalent"}>
                            D.Sc/Equivalent
                          </option>
                          <option value={"Doctoral/Equivalent"}>
                            Doctoral/Equivalent
                          </option>
                          <option value={"M.Phil/Equivalent"}>
                            M.Phil/Equivalent
                          </option>
                          <option value={"Post Graduate"}>Post Graduate</option>
                          <option value={"PG Diploma"}>PG Diploma</option>
                          <option value={"Under Graduate"}>
                            Under Graduate
                          </option>
                          <option value={"Diploma"}>Diploma</option>
                          <option value={"Pre-University/11th"}>
                            Pre-University/11th
                          </option>
                          <option value={"10+2"}>10+2</option>
                          <option value={"10th"}>10th</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Degree/Examination"}
                          name={"degree"}
                          required={true}
                          value={item.degree}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Discipline"}
                          name={"discipline"}
                          required={true}
                          value={item.discipline}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"College/Institute"}
                          name={"institute"}
                          required={true}
                          value={item.institute}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Board/University"}
                          name={"university"}
                          required={true}
                          value={item.university}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Passing Year"}
                          name={"year"}
                          required={true}
                          value={item.year}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Division/Class"}
                          name={"division"}
                          required={true}
                          value={item.division}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Select
                          disabled={preview}
                          plaintext={preview}
                          readOnly={preview}
                          style={{ minWidth: "max-content" }}
                          name={"grading"}
                          required={true}
                          value={item.grading}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        >
                          <option value={""} disabled>
                            Select
                          </option>
                          <option value={"Percentage"}>Percentage</option>
                          <option value={"GPA"}>GPA</option>
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"number"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Average Marks/GPA"}
                          name={"average"}
                          required={true}
                          value={item.average}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"number"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Total Marks/GPA"}
                          name={"total"}
                          required={true}
                          value={item.total}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Rank, if any"}
                          name={"rank"}
                          required={false}
                          value={item.rank}
                          onChange={(e) => {
                            setData({
                              ...data,
                              education: data.education.map((rec, recIndex) => {
                                if (recIndex === index) {
                                  return {
                                    ...rec,
                                    [e.target.name]: e.target.value,
                                  };
                                } else {
                                  return { ...rec };
                                }
                              }),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Button variant="danger" className="button--primary">
                          <MdDelete
                            disabled={preview}
                            size={20}
                            onClick={() => {
                              setData({
                                ...data,
                                education: data.education.filter(
                                  (rec, recIndex) => recIndex !== index
                                ),
                              });
                            }}
                          />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            {/* Section C Heading */}
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">
                  C. Experience Details:{" "}
                  <Button
                    disabled={preview}
                    onClick={() => {
                      setData({
                        ...data,
                        experience: [
                          ...data.experience,
                          {
                            organization: "",
                            designation: "",
                            from_date: "",
                            to_date: "",
                            pay_scale: "",
                            basic_pay: "",
                            grade_pay: "",
                            total_pay: "",
                            experience: "",
                          },
                        ],
                      });
                    }}
                    className="button--primary"
                  >
                    Add New Row
                  </Button>
                </h3>
              </Col>
            </Row>
            {/* Experience Table */}
            <Row>
              <Table bordered responsive size="lg">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Organization</th>
                    <th>Designation</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Scale of Pay/Pay Scale Matrix</th>
                    <th>Basic Pay/Pay in the Pay Scale Matrix</th>
                    <th>Grade Pay</th>
                    <th>Total Pay or Consolidated Amount</th>
                    <th>Nature of Experience (limit to 200 words)</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.experience?.map((item, index) => (
                    <tr>
                      <td>{index + 1}.</td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Organization"}
                          name={"organization"}
                          required={true}
                          value={item.organization}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Designation/Position"}
                          name={"designation"}
                          required={true}
                          value={item.designation}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"date"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"From"}
                          name={"from_date"}
                          required={true}
                          value={item.from_date}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"date"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"To"}
                          name={"to_date"}
                          required={true}
                          value={item.to_date}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Pay Scale"}
                          name={"pay_scale"}
                          required={true}
                          value={item.pay_scale}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Basic Pay"}
                          name={"basic_pay"}
                          required={true}
                          value={item.basic_pay}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Grade Pay"}
                          name={"grade_pay"}
                          required={true}
                          value={item.grade_pay}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          type={"text"}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Total Pay"}
                          name={"total_pay"}
                          required={true}
                          value={item.total_pay}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          plaintext={preview}
                          readOnly={preview}
                          // type={"text"}
                          as="textarea"
                          rows={2}
                          style={{ minWidth: "12rem" }}
                          placeholder={"Experience"}
                          name={"experience"}
                          required={true}
                          value={item.experience}
                          onChange={(e) => {
                            setData({
                              ...data,
                              experience: data.experience.map(
                                (rec, recIndex) => {
                                  if (recIndex === index) {
                                    return {
                                      ...rec,
                                      [e.target.name]: e.target.value,
                                    };
                                  } else {
                                    return { ...rec };
                                  }
                                }
                              ),
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Button variant="danger" className="button--primary">
                          <MdDelete
                            disabled={preview}
                            size={20}
                            onClick={() => {
                              setData({
                                ...data,
                                experience: data.experience.filter(
                                  (rec, recIndex) => recIndex !== index
                                ),
                              });
                            }}
                          />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            {/* Section D Heading */}
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">
                  D. Additional Remarks:
                </h3>
              </Col>
            </Row>
            <Row>
              {/* Remarks */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={12}
                    lg={12}
                    className="apply--form--label apply--form--label--required"
                  >
                    Special Qualification or Experience, including the computer
                    knowledge, which have not been included above (limit to 200
                    words):
                  </Form.Label>
                  <Col sm={12} md={12} lg={12}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      as="textarea"
                      rows={4}
                      placeholder={
                        "Special Qualification or Experience, including the computer knowledge, which have not been included above (limit to 200 words)"
                      }
                      name={"additional_remarks"}
                      required={true}
                      value={data.additional_remarks}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* Section E Heading */}
            <Row className="mt-3">
              <Col sm={12}>
                <h3 className="apply--section--heading">
                  E. Upload Documents:
                </h3>
                <p style={{ textAlign: "left" }}>(each file max 1024kb)</p>
              </Col>
            </Row>
            <Row>
              {/* photo */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Upload your Photo:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      disabled={preview}
                      type="file"
                      name={"photo"}
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Signature */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Upload your Signature:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      disabled={preview}
                      type="file"
                      name={"signature"}
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Age Proof */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Upload your Age Proof:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      disabled={preview}
                      type="file"
                      name={"age_proof"}
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* ID Proof */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Upload your ID Proof:
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      disabled={preview}
                      type="file"
                      name={"id_proof_document"}
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Educational Certificates */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Upload Educatonal Certifcates(Single PDF):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      disabled={preview}
                      type="file"
                      name={"educational_document"}
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Experience Certificates */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label apply--form--label--required"
                  >
                    Upload Experience Certifcates(Single PDF):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      disabled={preview}
                      type="file"
                      name={"experience_document"}
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
              {/* Category */}
              {!["General", ""].includes(data.category) && (
                <Col sm={12} md={12} lg={12}>
                  <Form.Group as={Row} className="apply--form--group">
                    <Form.Label
                      column
                      sm={12}
                      md={3}
                      lg={3}
                      className="apply--form--label "
                    >
                      Upload Category Certificate:
                    </Form.Label>
                    <Col sm={12} md={9} lg={9}>
                      <Form.Control
                        plaintext={preview}
                        readOnly={preview}
                        disabled={preview}
                        type="file"
                        name={"category_document"}
                        required={!["General", ""].includes(data.category)}
                        onChange={(e) => {
                          setData({
                            ...data,
                            [e.target.name]: e.target.files[0],
                          });
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              )}
              {/* Additional */}
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className="apply--form--group">
                  <Form.Label
                    column
                    sm={12}
                    md={3}
                    lg={3}
                    className="apply--form--label "
                  >
                    Upload Additional Document (if any):
                  </Form.Label>
                  <Col sm={12} md={9} lg={9}>
                    <Form.Control
                      plaintext={preview}
                      readOnly={preview}
                      disabled={preview}
                      type="file"
                      name={"additional_document"}
                      required={false}
                      onChange={(e) => {
                        setData({
                          ...data,
                          [e.target.name]: e.target.files[0],
                        });
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "1em",
              }}
            >
              <Button
                onClick={() => {
                  setPreview(!preview);
                }}
                className="px-4 mb-3 button--primary"
                style={{
                  width: "max-content",
                }}
              >
                {preview ? "Edit Form" : "Preview"}
              </Button>
              <Button
                type="submit"
                className="px-4 mb-3 button--primary"
                style={{
                  width: "max-content",
                }}
                disabled={!preview || loading}
              >
                Submit
              </Button>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
}
