import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import MemberCard from "../../components/Cards/MemberCard";
import { useEffect, useState } from "react";
import { getMemberList } from "../../api/api";

export default function BoardOfDirectors() {
  const [list, setList] = useState([]);
  useEffect(() => {
    getMemberList("bod").then((result) => {
      setList(result);
    });
  }, []);
  return (
    <>
      <Container className="about__members">
        <Row>
          <h2 className="heading--primary">Board of Directors</h2>
        </Row>
        {list.map((item, index) => (
          <MemberCard member={item}/>
        ))}
      </Container>
    </>
  );
}
