import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {
  FaTractor,
  FaRobot,
  FaBiohazard,
  FaRuler,
  FaWater,
  FaLandmark,
  FaBox,
  FaBolt,
  FaShippingFast,
  FaRecycle,
  FaHorse,
  FaBug,
  FaFish,
  FaGlobe,
  FaFlask,
  FaChalkboardTeacher,
  FaShieldAlt,
  FaChartLine,
  FaHandsHelping,
  FaLightbulb,
} from "react-icons/fa";

export default function IncubateeExpo2024() {
  return (
    <div className="program">
      <Container className="">
        <Row>
          <h2 className="heading--primary wow animate__animated animate__fadeInUp">
            Incubatees Expo-2024
          </h2>
        </Row>
        <div className="mt-3">
          <div className="program--image--div wow animate__animated animate__fadeInUp">
            <img
              src="/assets/images/Incubatee_Expo_24.png"
              className="program--image "
              alt="program"
            />
            {/* <a href="/apply/incubation">
              <Button className="w-100 button--primary">Apply Now</Button>
            </a> */}
          </div>
          <h3 className="heading--secondary">About the program</h3>
          <p className="program--content">
            The event aims to provide a platform for ABIF registered incubatees
            to exhibit their products, prototypes, and services. Also, this
            event will provide attendees with hands-on experiences and ample
            networking time; thus, allowing participants to connect with
            potential co-founders, FPOs, mentors, collaborators and sell
            products to campus community.
          </p>
          <h3 className="heading--secondary">Event Info</h3>
          <p className="program--content">
            {/* <span style={{ fontWeight: "bold" }}>Date: </span>
            24 - 25th April 2025
            <br /> */}
            <span style={{ fontWeight: "bold" }}>Venue: </span>
            Agri Business Incubation Foundation IIT Kharagpur, Kharagpur,
            Paschim Medinipur, West Bengal, India. PIN 721302.
          </p>
        </div>
      </Container>
    </div>
  );
}
