export const getContent = (category) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/content?category=${category}`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getNotices = (category) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/notice?category=${category}`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getStartupRecruitmentList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/startup-recruitment?display=active`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getPortfolioList = (category) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/portfolio?category=${category}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getStartupList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/startup?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};
export const getStartup = (slug) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/startup?startup=${slug}`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getFPOList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/fpo?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};
export const getFPO = (slug) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/fpo?fpo=${slug}`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getMentorList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/mentor?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getMentor = (slug) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/mentor?mentor=${slug}`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getMemberList = (type) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/member?type=${type}`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getCarouselList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/carousel?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getEventList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/events?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getPastEventList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/events/past?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getUpdatesList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/updates?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getTestimonialList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/testimonial?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getStatsList = () => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/stats?display=active`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const subcribeNewsletter = (email) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/subscribe`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ email }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          } else if (res.message === "The email has already been taken.") {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const submitQuery = ({ name, email, message }) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/query`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ name, email, message }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const submitAppointment = ({ name, email, message, mentor, phone }) => {
  return new Promise((resolve, reject) => {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL}/mentor/appointment`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ name, email, message, mentor, phone }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};
